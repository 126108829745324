import React, { FC, JSX, useMemo, useCallback, useState } from 'react';
import { ContractCostAgreement, Employee } from '../../api/marketx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { formatDateSwaggerZ, formatDateYmd } from '@mx-ui/helpers';
import { observer } from 'mobx-react-lite';
import { useAuthStore, useRootStore } from 'src/store/MobxStoreProvider';
import { DealItemStore } from 'src/store/DealItemStore';
import { v4 } from 'uuid';
import { formatNumber02, formatNumber3, pluralRus } from '@mx-ui/helpers';
import { HandShakeIcon } from '../ui/icons/HandShakeIcon';
import { IconApproval } from '../Deals/IconApprovalDeal';
import { ApproveResolutionControl } from 'src/components/Deals/ApproveResolutionControl';
import { agreementIsValidForCreateDeal } from './AgreementTabs';
import { AgreementItemStore } from '../../store/Clients/AgreementItemStore';
import { renderPriceCur } from '../../utils/renderFormat';
import { MarginalityPctSum } from '../ui/MarginalityPctSum';
import { ApproveHistoryDropDown } from '../Deals/ApproveResolutionControl/ApproveHistoryDropDown';
import { DialogAgreementClone } from './DialogAgreementClone';
import { AgreementListItemPositions } from './AgreementListItemPositions';
import { ThreadLink } from '../Bills/ThreadLink';
import { AttentionBlock } from '../Clients/AttentionBlock/AttentionBlock';
import { LabelNonResident } from 'src/views/clients/components/CompanyExtInfo';
import { MxLabel } from '@mx-ui/ui';
import { agreementDeviationLevelColors } from './agreementDeviationLevelColors';
import { agreementStateColors, agreementStateDictionary } from './AgreementStateSelect';
import { AgreementListStore } from '../../store/Clients/AgreementListStore';
import IconButton from '@mui/material/IconButton';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { PreviewMenu } from '../UploadFile/PreviewMenu';
import { getFormattedDocuments } from '../../views/clients/components/Order';
import { Link } from '@mx-ui/ui';
import useDocumentsPath from '../../hooks/useDocumentsPath';

const paddingBottom = 0.8;

const PREFIX = 'AgreementListItem';

const classes = {
  text: `${PREFIX}-text`,
};

export const StyledAgreementListItemPaper = styled(Paper)(({ theme }) => ({
  // border: '1px solid transparent',
  marginBottom: 16,
  padding: theme.spacing(1),
  [`& .${classes.text}`]: {
    fontSize: '14px',
    cursor: 'default',
  },
}));
interface AgreementListItemProps {
  agreement: ContractCostAgreement;
  isFullSize?: boolean;
  agreementsStore?: AgreementListStore;
}

// eslint-disable-next-line complexity
export const AgreementListItem: FC<AgreementListItemProps> = observer(({ agreement, isFullSize = false, agreementsStore }): JSX.Element => {
  const authStore = useAuthStore();
  const currentRole = authStore.getDefaultDealsEmployeeFilter();
  const theme = useTheme();
  const rootStore = useRootStore();
  const { profile } = rootStore.getAuth();
  const matchesXs = useMediaQuery('(min-width:450px)');
  const agreementStore = useMemo<AgreementItemStore>(() => {
    const store = new AgreementItemStore(rootStore);
    store.setAgreement(agreement);
    return store;
  }, [rootStore, agreement]);
  const agreementDocuments = getFormattedDocuments(agreement);
  const currentAgreementStateId = agreementStateDictionary[agreementStore.agreement?.simplifiedState?.code].id;
  const dealFrontCode = useMemo(() => v4(), []);
  const [isCreatingDeal, setIsCreatingDeal] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isShowButton = useMemo(
    () => agreementIsValidForCreateDeal(agreement, profile),
    [agreement, agreement.approval, agreement.positions, profile]
  );
  const handleCreateDeal = useCallback(() => {
    setIsCreatingDeal(true);
    const dealStore = new DealItemStore(rootStore);
    dealStore
      .createWithAgreementPositions(
        dealFrontCode,
        agreement
        // добавление позиций в заявку после привязки к соглашению
        // agreementStore.agreement?.positions.filter(i => !i.isArchive)
      )
      .then((res): void => {
        const dealCode = res.data?.deal?.code;
        if (!dealCode) {
          throw new Error('dealCode missing');
        }
        rootStore.getRouter().push('/app/deals/' + dealCode);
        setIsCreatingDeal(false);
      })
      .catch(e => {
        setIsCreatingDeal(false);
        console.error(e);
      });
  }, [rootStore, agreement, dealFrontCode]);

  const canCloneAgreement = agreement?.branchOfficeCode == profile?.chosenBranchOfficeCode;
  const matchesSmall = useMediaQuery('(min-width:500px)');
  const { color, bgColor, status } = agreementDeviationLevelColors(agreement.actualMarketDeviationLevel, theme);
  const { color: stateColor, bgColor: stateBgColor } = agreementStateColors(
    agreementStateDictionary[agreementStore.agreement?.simplifiedState?.code].id,
    theme
  );
  const handleEmployeeSetChange = useCallback(
    (emp?: Employee) => {
      agreementsStore.employeeSetChange(currentRole, emp);
    },
    [agreementsStore]
  );

  const handleCustomerChange = useCallback(
    (customerCode?: string) => {
      if (agreementsStore.request?.customerCodes?.includes(customerCode)) {
        return;
      }
      agreementsStore.mergeRequest({ customerCodes: customerCode ? [customerCode] : undefined });
    },
    [agreementsStore]
  );

  const handleCustomerRemoveChange = useCallback(
    (customerCode?: string) => {
      if (!agreementsStore.request?.customerCodes?.includes(customerCode)) {
        return;
      }
      const newCustomerCodes = agreementsStore.request?.customerCodes?.filter(i => i !== customerCode);
      agreementsStore.mergeRequest({ customerCodes: newCustomerCodes });
    },
    [agreementsStore]
  );

  const handleChangeFilterByStatus = useCallback(
    (status?: number) => {
      if (agreementsStore.request?.stateIds?.includes(status)) {
        return;
      }
      agreementsStore.mergeRequest({ stateIds: [status] });
    },
    [agreementsStore]
  );

  const handleRemoveState = useCallback(
    (status?: number) => {
      if (!agreementsStore.request?.stateIds?.includes(status)) {
        return;
      }
      const newStatusCodes = agreementsStore.request?.stateIds?.filter(i => i !== status);
      agreementsStore.mergeRequest({ stateIds: newStatusCodes });
    },
    [agreementsStore]
  );
  const { asQueryPath } = useDocumentsPath('agreements');
  return (
    <StyledAgreementListItemPaper>
      <Box display="flex" flexDirection="column">
        <Grid container sx={{ position: 'relative' }}>
          <Grid item container md={5} xs={12} flexDirection="column">
            <Grid item container flexDirection="column">
              <Grid item container alignItems="center" sx={{ maxWidth: '93%' }} mb={1}>
                <Grid mr={1} item>
                  <HandShakeIcon fontSize="medium" color="primary" />
                </Grid>
                <Grid item>
                  <Link href={`/app/agreements/${agreement.code}?${asQueryPath}`} color="primary" sx={{ fontWeight: 'bold' }}>
                    {`${agreement.code}${
                      matchesSmall ? ' с ' + formatDateYmd(agreement.validFrom) + ' по ' + formatDateYmd(agreement.validUntil) : ''
                    }`}
                  </Link>
                </Grid>
                <Grid item>
                  <ThreadLink link={`/app/thread/agreements/${encodeURIComponent(agreement.code)}`} color={'primary'} />
                </Grid>
              </Grid>

              {isShowButton && (
                <Grid item mb={1}>
                  <Button variant={'outlined'} size="small" disabled={isCreatingDeal} onClick={handleCreateDeal} color={'secondary'}>
                    Создать заявку по соглашению
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid container flexDirection={'column'} flexWrap={'nowrap'} mb={2}>
              <Grid item>
                <Typography variant="body2">ИНН {agreement.customer.inn || '—'}</Typography>
              </Grid>
              <Grid
                item
                sx={{ maxWidth: matchesXs ? 'auto' : '320px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                container
                alignItems={'center'}
                flexWrap={'nowrap'}
              >
                {!!agreement?.customer?.nonResident && (
                  <Grid item mr={1}>
                    <LabelNonResident />
                  </Grid>
                )}
                {!!agreement?.customer?.attentions?.length && (
                  <Grid item mr={1}>
                    <AttentionBlock attentions={agreement?.customer?.attentions} />
                  </Grid>
                )}
                <Grid item>
                  <Link
                    href={`/app/clients/${encodeURIComponent(agreement.customer.code)}`}
                    color="textSecondary"
                    sx={{ fontSize: 15, fontWeight: 600 }}
                  >
                    {agreement?.customer?.shortTitle || agreement?.customer?.title}
                  </Link>
                </Grid>
                {agreementsStore && agreement?.customer?.code ? (
                  agreementsStore.request?.customerCodes?.includes(agreement?.customer?.code) ? (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleCustomerRemoveChange(agreement?.customer?.code)}
                        title={`Убрать фильтрацию по ${agreement?.customer?.shortTitle || agreement?.customer?.title}`}
                      >
                        <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleCustomerChange(agreement?.customer?.code)}
                        title={`Найти соглашения ${agreement?.customer?.shortTitle || agreement?.customer?.title}`}
                      >
                        <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  )
                ) : null}
              </Grid>
            </Grid>
            <Grid item container>
              {agreement?.createdAt ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                      Создано
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                      {formatDateSwaggerZ(agreement.createdAt)}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {agreement?.validUntil ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                      Действительно до
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                      {formatDateSwaggerZ(agreement.validUntil)}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              {Boolean(agreementStore.agreement?.simplifiedState?.code) && (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      Статус
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Grid container alignItems="center">
                      <MxLabel
                        sx={{
                          cursor: 'pointer',
                          borderRadius: '16px',
                          fontWeight: '400',
                          color: stateColor,
                          backgroundColor: stateBgColor,
                        }}
                      >
                        <Box fontSize={14}>{agreementStore.agreement?.simplifiedState?.title}</Box>
                      </MxLabel>
                      {agreementsStore ? (
                        agreementsStore.request?.stateIds?.includes(currentAgreementStateId) ? (
                          <Box>
                            <IconButton
                              size={'small'}
                              onClick={() => handleRemoveState(currentAgreementStateId)}
                              title={`Убрать фильтрацию по статусу ${agreementStore.agreement?.simplifiedState?.title || ''}`}
                            >
                              <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </Box>
                        ) : (
                          <Box>
                            <IconButton
                              size={'small'}
                              onClick={() => handleChangeFilterByStatus(currentAgreementStateId)}
                              title={`Найти счета по статусу ${agreementStore.agreement?.simplifiedState?.title || ''}`}
                            >
                              <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </Box>
                        )
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {isFullSize && agreement?.contract ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      Договор
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Box display={'flex'}>
                      <Link
                        href={`/app/contracts/${encodeURIComponent(agreement.contract.code)}?customerCode=${encodeURIComponent(
                          agreement.customer.code
                        )}`}
                        color="text.secondary"
                        sx={{ fontSize: 14 }}
                      >
                        {agreement.contract.description}
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}

              {isFullSize && agreement?.name ? (
                <Grid container mb={1} alignItems="center">
                  <Grid item md={4} xs={6}>
                    <Typography fontSize="14px" color="text.secondary">
                      Название
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={6}>
                    <Box display={'flex'}>
                      <Typography fontSize="14px" color="text.secondary">
                        {agreement.name}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}

              {isFullSize && (
                <>
                  <Grid container mb={1} alignItems="center">
                    <Grid item md={4} xs={6}>
                      <Typography fontSize="14px" color="text.secondary">
                        Создал
                      </Typography>
                    </Grid>
                    <Grid item md={8} xs={6}>
                      <Box display={'flex'}>
                        <Typography fontSize="14px" color="text.secondary">
                          {(
                            (agreement.creator.surname || '') +
                            ' ' +
                            (agreement.creator.name || '') +
                            ' ' +
                            (agreement.creator.patronymic || '')
                          ).trim()}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container mb={1} alignItems="center">
                    <Grid item md={4} xs={6}>
                      <Typography fontSize="14px" color="text.secondary">
                        Реализатор
                      </Typography>
                    </Grid>
                    <Grid item md={8} xs={6}>
                      <Grid container alignItems="center">
                        <Typography fontSize="14px" color="text.secondary">
                          {`${agreement.distributor?.surname || ''} ${agreement.distributor?.name || ''} ${
                            agreement.distributor?.patronymic || ''
                          }`}
                        </Typography>
                        {agreementsStore ? (
                          agreementsStore.request?.employeeSetCode === `~emp~2~${agreement.distributor?.code}` ? (
                            <Box>
                              <IconButton
                                size={'small'}
                                onClick={() => handleEmployeeSetChange()}
                                title={`Убрать фильтрацию по ${agreement.distributor?.surname || ''} ${agreement.distributor?.name || ''} ${
                                  agreement.distributor?.patronymic || ''
                                }`}
                              >
                                <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box>
                              <IconButton
                                size={'small'}
                                onClick={() => handleEmployeeSetChange(agreement.distributor)}
                                title={`Найти соглашения ${agreement.distributor?.surname || ''} ${agreement.distributor?.name || ''} ${
                                  agreement.distributor?.patronymic || ''
                                }`}
                              >
                                <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                              </IconButton>
                            </Box>
                          )
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container mb={1} alignItems="center">
                    <Grid item md={4} xs={6}>
                      <Typography fontSize="14px" color="text.secondary">
                        Отрасль
                      </Typography>
                    </Grid>
                    <Grid item md={8} xs={6}>
                      <Box display={'flex'}>
                        <Typography fontSize="14px" color="text.secondary">
                          {agreement.customerOccupationTitle || agreement.customer.occupationTitle}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container mb={1} alignItems="center">
                    <Grid item md={4} xs={6}>
                      <Typography fontSize="14px" color="text.secondary">
                        Сегмент по потреблению
                      </Typography>
                    </Grid>
                    <Grid item md={8} xs={6}>
                      <Box display={'flex'}>
                        <Typography fontSize="14px" color="text.secondary">
                          {agreement.customerSegmentCode || agreement.customer.segmentCode}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item container md={7} xs={12}>
            <Grid item xs={12}>
              <Grid container mb={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography fontWeight={700} color="textPrimary">
                    Сумма соглашения
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" alignItems={'center'} flexDirection={'row'}>
                    <Typography fontWeight={700} color="textPrimary" marginRight={0.75}>
                      {renderPriceCur(agreement.totalCost)}
                    </Typography>
                    {Boolean(agreement?.positions?.some(i => i.usePsUnitCost && i.psUnitCost > 0)) ? (
                      matchesSmall ? (
                        <Chip label="Акция" size="small" icon={<MonetizationOnIcon />} />
                      ) : (
                        <MonetizationOnIcon fontSize="small" sx={{ color: '#616161' }} />
                      )
                    ) : undefined}
                    {Boolean(agreement?.positions?.some(i => i.isStocksControlDisabled)) ? (
                      <Chip label="Без контроля остатков" size="small" />
                    ) : undefined}
                  </Box>
                </Grid>
              </Grid>
              <Grid container mb={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography className={classes.text} color="text.secondary">
                    Маржинальность от МРЦ
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <MarginalityPctSum pct={agreement.marginalityPct} cost={agreement.marginality} />
                </Grid>
              </Grid>
              {profile?.features?.canSeeDealPrimeCostMargin && (
                <Grid container mb={1} alignItems="center">
                  <Grid item xs={6}>
                    <Typography className={classes.text} color="text.secondary">
                      Маржинальность от цены закупки
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <MarginalityPctSum pct={agreement.purchaseMarginFullCostPct} cost={agreement.purchaseMarginTotalCost} />
                  </Grid>
                </Grid>
              )}
              {profile?.features?.canSeeDealPrimeCostMargin && (
                <Grid container mb={1} alignItems="center">
                  <Grid item xs={6}>
                    <Typography className={classes.text} color="text.secondary">
                      Маржинальность от ПлС
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <MarginalityPctSum pct={agreement.plsMarginFullCostPct} cost={agreement.plsMarginTotalCost} />
                  </Grid>
                </Grid>
              )}
              {agreement.actualMarketDeviationPct && (
                <Grid container mb={1} alignItems="center">
                  <Grid item xs={6}>
                    <Typography className={classes.text} color="text.secondary">
                      Соответствие рынку
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems={'center'}>
                      <Grid item>
                        <Typography className={classes.text} color="text.secondary" sx={{ color: color }}>
                          {formatNumber02(agreement.actualMarketDeviationPct)} %
                        </Typography>
                      </Grid>
                      <Grid item ml={1}>
                        <MxLabel
                          sx={{
                            cursor: 'pointer',
                            borderRadius: '16px',
                            fontWeight: '400',
                            color: color,
                            backgroundColor: bgColor,
                          }}
                        >
                          <Box fontSize={14}>{status}</Box>
                        </MxLabel>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid container mb={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography className={classes.text} color="text.secondary">
                    Количество
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.text} color="text.secondary">
                    {formatNumber3(agreement.totalWeight / 1000)}
                    {` т`}
                  </Typography>
                </Grid>
              </Grid>
              {isFullSize && (
                <>
                  <Grid container mb={1} alignItems="center">
                    <Grid item xs={6}>
                      <Typography className={classes.text} color="text.secondary">
                        Предоплата
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.text} color="text.secondary">
                        {formatNumber02(agreement.payment.prepayPct)} %
                      </Typography>
                    </Grid>
                  </Grid>
                  {Number(agreement?.payment?.prepayPct || 0) < 100 && agreement?.payment?.postpayDays >= 0 && (
                    <Grid container mb={1} alignItems="center">
                      <Grid item xs={6}>
                        <Typography className={classes.text} color="text.secondary">
                          Отсрочка
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.text} color="text.secondary">
                          {agreement.payment.postpayDays} {pluralRus(agreement.payment.postpayDays, ['день', 'дня', 'дней'])}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              <Grid container pb={paddingBottom} alignItems="center">
                {agreementStore.approvalStore.approval?.stateTitle && (
                  <Grid item>
                    <Grid container alignItems="center" sx={{ position: 'relative' }}>
                      <IconApproval approvalStore={agreementStore.approvalStore} />
                      <ApproveResolutionControl sizeButton="medium" approvalStore={agreementStore.approvalStore} />
                      <Box display={'flex'} position="relative">
                        <ApproveHistoryDropDown approvalStore={agreementStore.approvalHistoryStore} />
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {agreementDocuments[agreementStore.agreementCode] && isFullSize ? (
                  <Grid item>
                    <PreviewMenu documents={agreementDocuments} positionCode={agreementStore.agreementCode} isWithBadge />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          {canCloneAgreement && (
            <Grid item sx={{ position: 'absolute', right: '-10px', top: '0' }}>
              <Grid container flexDirection={'column'}>
                <Grid item>
                  <DialogAgreementClone sourceCode={agreementStore.agreementCode} agreementStore={agreementStore} />
                </Grid>
                {agreementDocuments[agreement.code] && !isFullSize ? (
                  <Grid item>
                    <PreviewMenu documents={agreementDocuments} positionCode={agreement.code} isFromPosition />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          )}
        </Grid>

        {isFullSize && <AgreementListItemPositions agreement={agreement} documents={agreementDocuments} />}
      </Box>
    </StyledAgreementListItemPaper>
  );
});
